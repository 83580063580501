import PropTypes from 'prop-types'
import React from 'react'
import {Link} from "gatsby"

import clsx from 'clsx'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import logoIg from "./static/logoIgB.png";
import logoFb from "./static/logoFbB.png";


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: 'none',
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    snsLink: {
        padding: `12px`,
    },
    snsImg: {
        width: `30px`,
        margin: 0
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

const MyLink = props =>
    <>
        <Link to={props.link} style={{fontFamily: 'YuGothic, Yu Gothic, sans-serif'}} {...props}>{props.text}</Link>
    </>

const Header = ({siteTitle}) => {
    const classes = useStyles();

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    function handleDrawerOpen() {
        setOpen(true)
    }

    function handleDrawerClose() {
        setOpen(false)
    }

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                elevation={0}
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="default"
                        aria-label="Open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <a className={classes.snsLink} href="https://www.instagram.com/hisamatsu705/?hl=ja" target="_blank">
                        <img className={classes.snsImg} src={logoIg} alt="Instagramロゴ"/>
                    </a>
                    {/*
                    <a className={classes.snsLink} href="https://ja-jp.facebook.com/w.hisamatsu/" target="_blank">
                        <img className={classes.snsImg} src={logoFb} alt="Facebookロゴ"/>
                    </a>*/}
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? (
                            <ChevronLeftIcon/>
                        ) : (
                            <ChevronRightIcon/>
                        )}
                    </IconButton>
                </div>
                <Divider/>
                <List>
                    <ListItem button>
                        <ListItemText>
                            <MyLink
                                onClick={handleDrawerClose}
                                link="/#top"
                                text="トップ"/>
                        </ListItemText>
                    </ListItem>
                    {/*
                    <ListItem button>
                        <ListItemText>
                            <MyLink
                                onClick={handleDrawerClose}
                                link="/#news"
                                text="ニュース"/>
                        </ListItemText>
                    </ListItem>

                    <ListItem button>
                        <ListItemText>
                            <MyLink
                                onClick={handleDrawerClose}
                                link="/#cook"
                                text="お料理講座"/>
                        </ListItemText>
                    </ListItem>
                    */}
                    <ListItem button>
                        <ListItemText>
                            <MyLink
                                onClick={handleDrawerClose}
                                link="/#lunch"
                                text="ランチ"/>
                        </ListItemText>
                    </ListItem>
                    <ListItem button>
                        <ListItemText>
                            <MyLink
                                onClick={handleDrawerClose}
                                link="/#cafe"
                                text="カフェ"/>
                        </ListItemText>
                    </ListItem>
                    <ListItem button>
                        <ListItemText>
                            <MyLink
                                onClick={handleDrawerClose}
                                link="/#access"
                                text="アクセス"/>
                        </ListItemText>
                    </ListItem>
                </List>
            </Drawer>
        </div>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
