import React from "react"
import {makeStyles} from '@material-ui/core/styles'

import Layout from "../components/layout"
import SEO from "../components/seo"

const userStyles = makeStyles(() => ({
    mainContainer: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        webkitBoxOrient: 'vertical',
        webkitBoxDirection: 'normal',
        msFlexDirection: 'column',
        flexDirection: 'column',
        webkitBoxPack: 'center',
        msFlexPack: 'center',
        justifyContent: 'center',
        webkitBoxAlign: 'center',
        msFlexAlign: 'center',
        alignItems: 'center',
    },
    title: {
        fontFamily: 'JuliusSansOne',
        fontSize: '80px',
    },
    text: {
        marginBottom: '50px',
        fontFamily: 'YuGothic, Yu Gothic',
    }
}));

const NotFoundPage = () => {
    const classes = userStyles();

    return (
        <Layout>
            <SEO title="ページが見つかりませんでした"/>
            <div className={classes.mainContainer}>
                <h1 className={classes.title}>NOT FOUND</h1>
                <p className={classes.text}>お探しのページは見つかりませんでした。</p>
                <a className={classes.text} href={'/'}>ページトップに戻る</a>
            </div>
        </Layout>
    )
}

export default NotFoundPage
